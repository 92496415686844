<template>
  <div class="container">
    <!-- 头部 -->
    <header>
      <div class="top"> {{ $t('chat').title }}</div>
    </header>
    <div class="msg">{{ $t('chat').noOpen }}</div>
    <TabBar :active="'Chat'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import TabBar from '@/components/tabBar'
import PublicHeader from '@/components/publicHeader'
import { Token } from '@/utils/mm'

export default {
  components: {
    TabBar,
    PublicHeader
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.red {
  color: #FF5639;
}

.green {
  color: #02B270
}

.container {
  min-height: 100vh;
  font-size: 14px;
  padding-bottom: 70px;
  background: #F6F6F6;

  header {
    width: 100%;
    height: 120px;
    background-image: url(../../assets/img/heard_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .top {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: 17px;
      color: #333333;
    }
  }

  .msg {
    margin: 150px 45px;
    height: 65px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
